.ck.ck-content h2.redCm2c {
    color: #CC1408;
}

.ck.ck-content h3.redCm2c {
    color: #CC1408;
}

.ck.ck-content p.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.ck.ck-content a.redCm2cButton {
    background-color: #ef6a6a;
    border-color: #e74747;
}

.ck.ck-content a {
    background-color: #ef6a6a;
    border-color: #e74747;
    color:white;
    text-decoration: none;
    padding: 5px;
    border: 1px solid #e74747;
    border-radius: 5px;
}

.redCm2c {
    color: #CC1408;
}

p.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

col > a {
    background-color: #ef6a6a;
    border-color: #e74747;
    color:white !important;
    text-decoration: none !important;
    padding: 5px;
    border: 1px solid #e74747;
    border-radius: 5px;
}

a:hover {
    cursor: pointer;
}