.row-block {
  background-color: white;
  min-height: 120px;
}

.selected {
  background-color: aquamarine !important;
}

.block {
  border: 1px dashed #8d8d8d;
}

.block:hover {
  background-color: #e3e3e3;
}

.row-block:hover {
  background-color: #efefef;
}

.menu {
  background-color: white;
}

.paper-shadow {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.clickable:hover {
  cursor: pointer;
}

.menu-flex > * {
  margin-left: 5px;
}