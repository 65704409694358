.preview p {
    font-size: 7px;
}

.preview h2 {
    font-size: 11px;
}

.card-page:hover {
    cursor: pointer;
}

.card-page {
    border-radius: 5px;
}

body.back-pages {
    font-family: Nunito,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    background: url("https://dcfcn9te0xk7k.cloudfront.net/cm2c/back-login-transparent-6420bbd15765d.png") no-repeat center center fixed !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.11) !important;
}