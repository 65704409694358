.box{
  box-shadow: inset #006e96 0 0 0 5px;
}

.box:hover{
  cursor: pointer;
  background-color: rgba(0, 111, 122, 0.28);
}

.box-selected{
  background-color: rgba(0, 82, 91, 0.42);
}

.line-editable:hover {
  outline: 5px solid rgba(2, 29, 98, 0.75);
  z-index: 500;
}