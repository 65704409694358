.card-page {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: .5s;
}

.card-page:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 500px;
    transform: translate(-140%, -50%);
    border-radius: 50%;
    background-color: #06064b;
    opacity: 0.8;
    transition: .6s
}

.card-page:hover:after {
    transform: translate(-50%, -50%)
}

.menu-hover {
    position: absolute;
    z-index: 2;
    top: 12%;
    left: 25%;
    transform: translate(-2000px, -50%);
    color: #fff;
    transition: .3s;
    transition-timing-function: ease-in
}

.card-page:hover .menu-hover {
    transform: translate(-20%, -10%);
    transition-timing-function: ease;
}

#wrap {   }
#frame {
    scale: 0.5;
    width: 615px;
    height: 600px;
    margin-left: -160px;
    margin-top: -157px;
    overflow-y: hidden;
}

#frame html {
    overflow: hidden;
}
