.button-col {
    background-color: rgba(0, 45, 164, 0.75);
    color: white;
    padding: 7px;
    border-radius: 5px;
    font-size: 20px;
}

.button-col-media {
    background-color: rgba(255, 22, 69, 0.75);
    color: white;
    padding: 7px;
    border-radius: 5px;
    font-size: 20px;
}

.button-col:hover {
    cursor: pointer;
}

.button-line {
    background-color: rgba(2, 29, 98, 0.75);
    color: white;
    padding: 5px;
    font-size: 25px;
}

.button-col {
    background-color: rgba(255, 79, 79, 0.85);
    color: white;
    padding: 5px;
    font-size: 25px;
}

.button-line:hover {
    cursor: pointer;
}

.col-editable:hover {
    outline: 5px solid rgba(255, 79, 79, 0.75);
    z-index: 1000;
}
